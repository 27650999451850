import React, { ReactNode, useState } from "react";
import { Navbar, Nav, Image } from "react-bootstrap";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  TextField,
} from "@mui/material";
import { BsMessenger } from "react-icons/bs";

import Logo from "../assets/image/Logoenb.png";

import "../assets/css/components/main.css";

const Main = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="overflow-hidden">
      <div className="header">
        <Header />
      </div>
      <div>{children}</div>
      <div className="message text-white p-3" onClick={() => setOpen(true)}>
        <BsMessenger fontSize={60} />
      </div>
      <ModalMessage show={open} setOpen={setOpen} />
    </div>
  );
};

export default Main;

function Header() {
  const [fixed, setFixed] = useState(false);

  function setFixedFn() {
    if (window.scrollY >= 150) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  }

  window.addEventListener("scroll", setFixedFn);
  return (
    <Navbar
      expand="lg"
      className={`py-4  ${
        fixed ? "nav-fix" : "nav-static"
      } d-flex justify-content-between`}
    >
      <div className="container">
        <div>
          <Navbar.Brand className="text-white">
            <a href="#home" className="fw-bold fs-5  text-decoration-none">
              <Image style={{ maxHeight: 50 }} src={Logo} />
            </a>
          </Navbar.Brand>
        </div>
        <div>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            style={{ background: "#ffffff47" }}
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <a
                href={"#home"}
                style={fixed ? { color: "#291b3f" } : { color: "#fff" }}
                className="fw-bold fs-5 text-nav text-decoration-none mx-3"
              >
                TRANG CHỦ
              </a>
              <a
                href={"#about"}
                style={fixed ? { color: "#291b3f" } : { color: "#fff" }}
                className="fw-bold fs-5  text-nav text-decoration-none mx-3"
              >
                GIỚI THIỆU
              </a>
              <a
                href={"#service"}
                style={fixed ? { color: "#291b3f" } : { color: "#fff" }}
                className="fw-bold fs-5 text-nav mx-3 text-decoration-none"
              >
                DỊCH VỤ
              </a>
              <a
                href={"#partner"}
                style={fixed ? { color: "#291b3f" } : { color: "#fff" }}
                className="fw-bold fs-5 text-nav mx-3 text-decoration-none"
              >
                DỰ ÁN
              </a>
            </Nav>
          </Navbar.Collapse>
        </div>
      </div>
    </Navbar>
  );
}

const ModalMessage = ({ show, setOpen }: { show: boolean; setOpen: any }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [mail, setMail] = useState("");
  const [question, setQuestion] = useState("");

  const handleClose = () => {
    setOpen(false);
    setName("");
    setPhone("");
    setMail("");
    setQuestion("");
  };

  // eslint-disable-next-line no-useless-escape
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const postData = async () => {
    if (name && phone && mail) {
      if (regex.test(mail)) {
        setLoading(true);
        await submitForm({
          name,
          phone,
          mail,
          question,
        });
        setLoading(false);
        handleClose();
      } else {
        alert("Vui lòng nhập đúng định dạng Email");
      }
    } else {
      alert("Vui lòng nhập đủ thông tin!!");
    }
  };
  return (
    <Dialog open={show} onClose={handleClose} fullWidth>
      <DialogTitle>Liên Hệ Với Chúng Tôi</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Họ Và Tên"
          fullWidth
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Số Điện Thoại"
          type="number"
          required
          fullWidth
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Email"
          required
          type="email"
          fullWidth
          value={mail}
          onChange={(e) => setMail(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Câu Hỏi"
          type="text"
          fullWidth
          multiline
          rows={5}
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Đóng</Button>
        <Button variant="contained" onClick={postData} disabled={loading}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const submitForm = (data: any) => {
  const dataFetch = fetch(
    `https://script.google.com/macros/s/AKfycbxJQXzDKvOvtSZAy-lMyn69Fa33WZyfof_JU6BpYOPxywOVQyUq7iIBuobmjrUQKLON/exec`,
    {
      method: "post",
      body: JSON.stringify(data),
    }
  ).then((response) => {
    response.json().then((data) => {
      if (data.message === "success") {
        alert("Gửi yêu cầu thành công");
      }
      return data;
    });
  });
  return dataFetch;
};
