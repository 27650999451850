import { initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import { BsFacebook, BsPhone, BsTelegram, BsTwitter } from "react-icons/bs";
import { GoLocation } from "react-icons/go";
import "../assets/css/components/Home.css";
import Main from "../components/MainPage";

import homeHeader from "../assets/image/bgHome.png";
import card1 from "../assets/image/card1.png";
import card2 from "../assets/image/card2.png";
import card3 from "../assets/image/card3.png";
import card4 from "../assets/image/card4.png";
import gioithieu from "../assets/image/gioithieu.png";
import Logo from "../assets/image/Logoenb.png";
import lydo1 from "../assets/image/lydo1.png";
import lydo2 from "../assets/image/lydo2.png";
import lydo3 from "../assets/image/lydo3.png";

const firebaseConfig = {
  apiKey: "AIzaSyCfU3p7Qp1GEqR3KrvMraYfnsNoGVun9H0",
  authDomain: "enb-job-dev.firebaseapp.com",
  projectId: "enb-job-dev",
  storageBucket: "enb-job-dev.appspot.com",
  messagingSenderId: "981376715956",
  appId: "1:981376715956:web:ffc1a47f531d30f158dbe1",
  measurementId: "G-2NRES82494",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

interface IAdvisor {
  img: string;
  name: string;
  position: string[];
}

interface ITeam {
  img: string;
  position: string;
  name: string;
}

interface IPartner {
  img: string;
  link?: string;
}

interface IBanner {
  about_desc: string[];
  about_feature: string[];
  about_img: string;
  banner_img: string;
  banner_title: string[];
}

const HomePage = () => {
  return (
    <Main>
      <Banner />
      <div style={{ minHeight: "100vh" }}>
        <div className="pt-3 pt-md-5">
          <Session4 />
        </div>
        <div className="pt-3 pt-md-5">
          <Session2 />
        </div>
        <div className="pt-3 pt-md-5">
          <Session3 />
        </div>
        <div className="pt-3 pt-md-5">
          <Adsvitor />
        </div>
        <div className="pt-3 pt-md-5">
          <Session5 />
        </div>
        <div className="pt-3 pt-md-5">
          <Session6 />
        </div>
        <div className="pt-3 pt-md-5">
          <Footer />
        </div>
      </div>
    </Main>
  );
};

export default HomePage;

const Banner = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${homeHeader})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      className="vh-100 vw-100"
      id="home"
    >
      <div className="container h-100">
        <div className="d-flex h-100 align-items-center justify-content-center">
          <div
            className="text-white  fw-bold text-center "
            style={{ fontSize: "40px" }}
          >
            Đội Ngũ Đào Tạo - Cung Cấp Nhân Sự Cho Thị Trường <br /> Blockchain
            - Chuyên Nghiệp Và Kinh Nghiệm
          </div>
        </div>
      </div>
    </div>
  );
};

const Session2 = () => {
  return (
    <div className="container">
      <div
        className="fs-5 pt-4"
        style={{ textTransform: "uppercase", color: "#7D00FF" }}
      >
        Lý do
      </div>
      <div className="fs-3 fw-bold py-2" style={{ color: "#1E1E1E" }}>
        Chọn chúng tôi
      </div>
      <div style={{ color: "#5A7184" }}>
        ENB Job cam kết thực hiện sứ mệnh trở thành cầu nối tin cậy giữa người
        tìm việc và nhà tuyển dụng.
      </div>
      <br />
      <div style={{ color: "#5A7184" }} className="w-100 w-md-50 d-block mt-2">
        Dựa trên chính sách lắng nghe, thấu hiểu và tôn trọng quan điểm cũng như
        mong muốn của người tìm việc cũng như nhà tuyển dụng, mục đích cuối cùng
        của chúng tôi là phù hợp hiệu quả với cả hai bên.
      </div>
      <div className="line-color mt-3"></div>
      <div className="row mt-2 mt-md-4 gx-5 align-items-center">
        <div className="col-12 col-md-4 ">
          <div className="item-lydo">
            <div className="px-4 py-5">
              <Image src={lydo1} />
              <div
                style={{ fontWeight: 700, color: "#16133E" }}
                className="py-3"
              >
                Đào Tạo Nhân Sự
              </div>
              <div style={{ fontWeight: 600, color: "#16133E" }}>
                Đào tạo nguồn nhân lực – KOL cho thị trường Blockchain.
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="item-lydo">
            <div className="px-4 py-5">
              <Image src={lydo2} />
              <div
                style={{ fontWeight: 700, color: "#16133E" }}
                className="py-3"
              >
                Cung Cấp Nhân Sự - KOL
              </div>
              <div style={{ fontWeight: 600, color: "#16133E" }}>
                Giúp giải quyết bài toán thiếu nguồn Nhân Sự của các Công Ty,
                Cộng Đồng Blockchain
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="item-lydo">
            <div className="px-4 py-5">
              <Image src={lydo3} />
              <div
                style={{ fontWeight: 700, color: "#16133E" }}
                className="py-3"
              >
                Kết nối
              </div>
              <div style={{ fontWeight: 600, color: "#16133E" }}>
                Kết nối các Công Ty, Nhân Sự, Cộng Đồng, Ventures
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Session3 = () => {
  return (
    <div className="container" id="service">
      <div className="row">
        <div className="col-12 col-md-4">
          <div
            className="fs-5 pt-4"
            style={{ textTransform: "uppercase", color: "#7D00FF" }}
          >
            SẢN PHẨM & DỊCH VỤ
          </div>
          <div className="fs-3 fw-bold py-3">Các dịch vụ chính</div>
          <div className="line-color mt-3"></div>
        </div>
        <div className="col-12 col-md-4">
          <div className="my-3">
            <Image className="w-100" src={card1} />
          </div>
          <div className="my-3">
            <Image className="w-100" src={card3} />
          </div>
        </div>
        <div className="col-12 col-md-4">
          <div className="my-3">
            <Image className="w-100" src={card2} />
          </div>
          <div className="my-3">
            <Image className="w-100" src={card4} />
          </div>
        </div>
      </div>
    </div>
  );
};
const Session4 = () => {
  const [about, setAbout] = useState<IBanner>();

  useEffect(() => {
    (async () => {
      const querySnapshot = await getDocs(collection(db, "banner"));
      querySnapshot.forEach((data) => {
        setAbout(data.data() as IBanner);
      });
    })();
  }, []);

  return (
    <div className="container" id="about">
      <div className="row">
        <div className="col-12 col-md-6">
          <div
            className="fs-5 pt-4"
            style={{ textTransform: "uppercase", color: "#7D00FF" }}
          >
            Giới Thiệu
          </div>
          <div className="fs-3 fw-bold py-3">Chúng tôi là...</div>
          <div className="line-color mt-3"></div>
          <div style={{ color: "#5a7184" }}>
            <div className="my-3">
              ENB Headhunter làm cho việc tuyển Nhân Sự trở nên đơn giản hơn bao
              giờ hết. Giúp cho các Bạn yêu thích công việc trong Blockchain tìm
              được công việc mơ ước một cách dễ dàng hơn.
            </div>
            <div className="my-3">
              Đào tạo được một Nhân Sự có năng lực làm việc, là giúp cho một
              người có công việc. Đào tạo cho nhiều Nhân Sự đi làm việc cho
              nhiều Công Ty, Cộng Đồng là hỗ trợ cho các Quý Đối Tác chút công
              sức, cũng như giúp cho Việt Nam mình vươn xa ra Thế Giới hơn.
            </div>
            <ul className="my-4">
              <li className="my-2 " style={{ color: "#7D00FF" }}>
                Kết nối các Dự Án, VC trong và ngoài lãnh thổ Việt Nam lại gần
                nhau hơn.
              </li>
              <li className="my-2 " style={{ color: "#7D00FF" }}>
                Hỗ trợ cho các Bạn trẻ yêu thích công việc trong Blockchain được
                chọn lựa công việc phù hợp vối năng lực, sở thích, chuyên môn.
              </li>
              <li className="my-2 " style={{ color: "#7D00FF" }}>
                Chia sẻ kiến thức, kinh nghiệm thực chiến.
              </li>
              <li className="my-2 " style={{ color: "#7D00FF" }}>
                Làm việc tận Tâm, uy tín.
              </li>
            </ul>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <Image className="w-100" src={about?.about_img || gioithieu} />
        </div>
      </div>
    </div>
  );
};

const Adsvitor = () => {
  const [advisors, setAdvisors] = useState<IAdvisor[]>([]);

  useEffect(() => {
    (async () => {
      let list_advisor: IAdvisor[] = [];
      const querySnapshot = await getDocs(collection(db, "advisors"));
      querySnapshot.forEach((data) => {
        list_advisor.push(data.data() as IAdvisor);
      });
      setAdvisors(list_advisor);
    })();
  }, []);

  return (
    <div className="container pb-5">
      <div className="text-center ">
        <div className="fw-bold fs-1 ">Advisor</div>
        <div className="line-color mx-auto"></div>
      </div>
      <div className="row justify-content-center g-3 mt-4">
        {advisors.map((team, index) => {
          return (
            <div key={index} className="col-12 col-md-4 ">
              <div className="d-flex align-items-center p-4 ">
                <Image className="w-100" src={team.img} roundedCircle />
              </div>
              <div className="text-center fs-3">{team.name}</div>
              {team.position.map((p, i) => (
                <div key={i}>
                  <div
                    className="text-center fs-4 w-100"
                    style={{ color: "#7d00ff" }}
                  >
                    {p}
                  </div>
                  <br />
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Session5 = () => {
  const [teams, setTeams] = useState<ITeam[]>([]);

  useEffect(() => {
    (async () => {
      let list_team: ITeam[] = [];
      const querySnapshot = await getDocs(collection(db, "teams"));
      querySnapshot.forEach((data) => {
        list_team.push(data.data() as ITeam);
      });
      setTeams(list_team);
    })();
  }, []);

  return (
    <div className="container pb-5">
      <div className="text-center ">
        <div className="fw-bold fs-1 ">Teams</div>
        <div className="line-color mx-auto"></div>
      </div>
      <div className="row justify-content-center g-3 mt-4">
        {teams.map((team, index) => {
          return (
            <div key={index} className="col-6 col-md-3 ">
              <div className="d-flex align-items-center p-4 ">
                <Image className="w-100" src={team.img} roundedCircle />
              </div>
              <div className="text-center fs-3">{team.name}</div>
              <div className="text-center fs-4" style={{ color: "#7d00ff" }}>
                {team.position}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Session6 = () => {
  const [partners, setPartner] = useState<IPartner[]>([]);

  useEffect(() => {
    (async () => {
      let list_partner: IPartner[] = [];
      const querySnapshot = await getDocs(collection(db, "partners"));
      querySnapshot.forEach((data) => {
        list_partner.push(data.data() as IPartner);
      });
      setPartner(list_partner);
    })();
  }, []);

  return (
    <div className="container pb-5" id="partner">
      <div className="text-center ">
        <div className="fw-bold fs-1 ">Đối Tác</div>
        <div className="line-color mx-auto"></div>
      </div>
      <div className="row justify-content-center g-3 mt-4">
        {partners.map((partner, index) => {
          return (
            <div key={index} className="col-12 col-md-3">
              <a href={partner.link} target="_blank" rel="noreferrer">
                <div className="card-partner d-flex align-items-center p-5 ">
                  <Image className="img-logo w-100" src={partner.img} />
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Footer = () => {
  return (
    <div style={{ backgroundColor: "#16143D" }}>
      <div className="container text-white text-center text-md-start">
        <div className="row py-5 ">
          <div className="col-12 col-md-5">
            <Image src={Logo} style={{ maxHeight: 50 }} />
            <div className="text-white my-4 w-75 w-md-50 mx-auto mx-md-0">
              Nơi đào tạo và cung cấp Nhân Sự - KOL cho Thị Trường Blockchain
            </div>
            <div className="d-flex justify-content-center justify-content-md-start mb-4">
              <div>
                <BsFacebook color="#fff" className="fs-1 d-inline-block me-4" />
              </div>
              <div>
                <BsTelegram color="#fff" className="fs-1 d-inline-block me-4" />
              </div>
              <div>
                <BsTwitter color="#fff" className="fs-1 d-inline-block " />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7">
            <div className="row g-3">
              <div className="col-12 col-md-4">
                <div className="fs-4 mb-2 mb-md-4">DỊCH VỤ</div>
                <div className="mb-2">Blog</div>
                <div className="mb-2">Partner</div>
                <div className="mb-2">Apps</div>
              </div>
              <div className="col-12 col-md-4">
                <div className="fs-4 mb-2 mb-md-4">Giới Thiệu</div>
                <div className="mb-2">About</div>
                <div className="mb-2">Terms</div>
                <div className="mb-2">Privacy Policy</div>
                <div className="mb-2">Careers</div>
              </div>
              <div className="col-12 col-md-4 text-start">
                <div className="fs-4 mb-2 mb-md-4  text-center">LIÊN HỆ</div>
                <div className="mb-2">We're Always Happy to Help</div>
                <div className="mb-2">
                  <BsPhone className="me-2" />
                  02439960909
                </div>
                <div className="mb-2">
                  <AiOutlineMail className="me-2" />
                  enbjobss@gmail.com
                </div>
                <div className="mb-2">
                  <GoLocation className="me-2" />
                  Khu A, Tập thể bưu điện, Xã Yên Viên, Huyện Gia Lâm, TP.Hà Nội
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center pb-5" style={{ color: "#919191" }}>
          Copyright © 2022. Crafted with love.
        </div>
      </div>
    </div>
  );
};

{
  /* <div className="col-12 col-md-6">
          <Image width={"100%"} src={daotao} />
        </div>
        <div className="col-12 col-md-6" style={{ height: "60%" }}>
          <div
            className="text-center py-3"
            style={{ backgroundColor: "#7D00FF", borderRadius: "8px" }}
          >
            <div className="fs-2 fw-bold py-4">Đào Tạo Nhân Sự</div>
            <div style={{ width: "80%" }} className="mx-auto fs-5 pb-3">
              Đội Ngũ Chuyên Gia dày dặn kinh nghiệm của ENB sẽ trực tiếp cầm
              tay chỉ việc cho từng Học Viên, vừa học lý thuyết vừa thực hành
              công việc, đảm bảo mỗi Nhân Sự sau khi đào tạo xong, đều đem lại
              sự hài lòng cho Quý Đối Tác.
              <br />
              <br /> Đảm bảo Nhân Sự sau khi đào tạo xong, khi cung cấp đi các
              Công Ty đều đem lại sự hài lòng cho cả Nhân Sự lẫn Công Ty.
            </div>
          </div>
        </div> */
}
